import { useSelector } from 'react-redux';
import s from './Place.module.scss'
import { useParams } from 'react-router-dom'
import { MapBox } from '../../components/MapBox/MapBox';
import { AudioPlayerComponent } from '../../components/AudioPlayer/AudioPlayer';
import { useTranslation } from 'react-i18next';
import { NavigationBar } from '../../components/NavigationBar/NavigationBar';
import { stringToNumber } from '../../helper';

export const Place = () => {
    const { places } = useSelector(state => state.places)
    const { t } = useTranslation()
    const { id } = useParams()
    const points = []
    const center = null
    const getPlaceData = (id) => {
        const place = places?.find((point, index) => stringToNumber(point.name) === id);
        return place ? place : null
    }
    const placesData = getPlaceData(Number(id))
    const pointInMap = places?.filter((point, index) => stringToNumber(point.name) === Number(id))
    window.scrollTo({ top: 0 })
    return (
        <div>
            <div className={s.high_title}>
                {t('places')}
            </div>
            <div className={s.wrapper}>
                <NavigationBar
                    mainPage={t('mainLink')}
                    secondLevel={t('history')}
                    secondLink={'/history'}
                    thirdLevel={t('places')}
                    thirdLink={'/history/places'}
                    fourthLevel={placesData?.name} />
                <div className={s.person_title}>
                    {placesData?.name}
                </div>
                <div className={s.person_information}>
                    <div className={s.left_block}>
                        <img src={placesData?.place.images[1]} alt="" />
                        <AudioPlayerComponent src={placesData?.audio} data={places} link={'/history/places'} />
                    </div>
                    <div
                        dangerouslySetInnerHTML={{ __html: placesData?.about }}
                    />
                </div>
                <MapBox
                    points={pointInMap}
                    center={[placesData?.place.longitude, placesData?.place.latitude]}
                    isItem={true} />
            </div>
        </div>
    )
}