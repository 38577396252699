import { configureStore } from "@reduxjs/toolkit";
import eventsReducer from './eventsSlice'
import personsReducer from "./personsSlice";
import placesReducer from "./placesSlice"
import organizationsReducer from "./organizationsSlice"
import devsReducer from "./devsSlice"

export const store = configureStore({
    reducer: {
        events: eventsReducer,
        persons: personsReducer,
        places: placesReducer,
        organizations: organizationsReducer,
        devs: devsReducer
    },
})