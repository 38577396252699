import { useTranslation } from 'react-i18next'
import s from './ButtonSwither.module.scss'

export const ButtonSwitcher = ({buttonActive, setButtonActive}) => {
    const { t } = useTranslation()

    return (
        <div className={s.switcher_container}>
        <div className={s.sectionButtons}>
            <button onClick={() => setButtonActive(true)}
                className={buttonActive ? s.buttonActive : s.buttonPassive}>{t('list')}</button>
            <button onClick={() => setButtonActive(false)}
                className={buttonActive ? s.buttonPassive : s.buttonActive}>{t('map')}</button>
        </div>
    </div>
    )
}