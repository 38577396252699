import s from './Development.module.scss'
import { ReactComponent as Filter } from '../../assets/filter.svg'
import { ReactComponent as Cross } from '../../assets/cross.svg'
import { ReactComponent as Search } from '../../assets/search.svg'
import img from './photo.png'
import { CardItem } from '../../components/CardItem/CardItem'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { FilterBox } from '../../components/FilterBox/FilterBox'
import { NavigationBar } from '../../components/NavigationBar/NavigationBar'


export const Development = () => {
    const { devs, language } = useSelector(state => state.devs)
    const [searchTerm, setSearchTerm] = useState('')
    const [isFilter, setIsFilter] = useState(false)
    const [selectedCategories, setSelectedCategories] = useState([])
    const [filtereDevs, setFilteredDevs] = useState(devs)
    const { t } = useTranslation()

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value)
    }

    const handleCategoryChange = (category) => {
        setSelectedCategories(prevSelected =>
            prevSelected.includes(category)
                ? prevSelected.filter(c => c !== category)
                : [...prevSelected, category]
        )
    }

    useEffect(() => {
        let filtered = devs

        if (searchTerm) {
            filtered = filtered.filter(dev =>
                dev.name.toLowerCase().includes(searchTerm.toLowerCase())
            )
        }

        if (selectedCategories.length > 0) {
            filtered = filtered.filter(dev =>
                selectedCategories.includes(dev.development.department_filter_translations[language -1].name)
            )
        }

        setFilteredDevs(filtered)
    }, [searchTerm, selectedCategories, devs])


    const getCategoryCounts = (devs) => {
        const categoryCounts = {}
        devs?.forEach(dev => {
            const categoryName = dev.development.department_filter_translations[language -1].name
            if (categoryCounts[categoryName]) {
                categoryCounts[categoryName]++
            } else {
                categoryCounts[categoryName] = 1
            }
        })

        return Object.keys(categoryCounts).map(categoryName => ({
            name: categoryName,
            count: categoryCounts[categoryName]
        }))
    }
    const categories = getCategoryCounts(devs)
    useEffect(() => {
        window.scrollTo({ top: 0 })
    }, [])
    return (
        <div>
            <div className={s.high_title}>
                {t('development')}
            </div>
            <div className={s.wrapper}>
                <NavigationBar
                    mainPage={t('mainLink')}
                    secondLevel={t('development')} />
                <div className={s.setting_line}>
                    <div className={s.filter_search_container}>
                        <div className={s.filter_container}>
                            <div onClick={() => setIsFilter(!isFilter)} className={`${s.filter} ${isFilter ? s.active : ''}`}>
                                {isFilter ? <Cross /> : <Filter />}
                            </div>
                            {isFilter && <FilterBox
                                categories={categories}
                                selectedCategories={selectedCategories}
                                handleCategoryChange={handleCategoryChange} />}
                        </div>
                        <div className={s.search}>
                            <input
                                type="text"
                                value={searchTerm}
                                onChange={handleSearchChange}
                                placeholder={t('devsPlaceholder')}
                            />
                            <Search />
                        </div>
                    </div>
                </div>
                <div className={s.organizations_container}>
                    {filtereDevs?.map((dev, index) => (
                        <CardItem
                            key={index}
                            link={`development/${dev.development.pk}`}
                            image={dev.development.logo}
                            text={dev.name} />
                    ))}
                </div>
            </div>
        </div>
    )
}