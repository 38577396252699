import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


export const getEvents = createAsyncThunk(
  'events/fetchText',
  async (language, {rejectWithValue}) => {

    try {
      const response = await fetch(`https://nasbguide.krokam.by/api/rest/eventlocales/?lang=${language}`)
      if (!response.ok) {

        throw new Error(response.statusText);
      }
      const data = await response.json()
      return data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)


const eventsSlice = createSlice({
  name: 'events',
  initialState: {
    error: null,
    status: null,
    events: null,
    language: localStorage.getItem('language') || 'be'
  },
  reducers: {
    setLocale: (state, action) => {
      state.language = action.payload
    },
  }, 
  extraReducers: (builder) => {
    builder
      .addCase(getEvents.pending, (state) => {
        state.status = 'loading';
        state.error = null
      })
      .addCase(getEvents.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.events = action.payload;
      })
      .addCase(getEvents.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
     
  },
})

export const {
  setLocale
} = eventsSlice.actions
export default eventsSlice.reducer

