import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { stringToNumber } from "../helper";

export const getOrganizations = createAsyncThunk(
	'organizations/fetchText',
	async (language, { rejectWithValue }) => {

		try {
			const response = await fetch(`https://nasbguide.krokam.by/api/rest/organizationlocales/?lang=${language}`)
			if (!response.ok) {

				throw new Error(response.statusText);
			}
			const data = await response.json()
			return data
		} catch (error) {
			return rejectWithValue(error.message)
		}
	}
)

const organizationsSlice = createSlice({
	name: 'organizations',
	initialState: {
		error: null,
		status: null,
		organizations: null,
		language: null,
	},
	reducers: {
		setOrgLocale: (state, action) => {
		  state.language = action.payload
		},
	  }, 
	extraReducers: (builder) => {
		builder
			.addCase(getOrganizations.pending, (state) => {
				state.status = 'loading';
				state.error = null
			})
			.addCase(getOrganizations.fulfilled, (state, action) => {
				state.status = 'succeeded';
				state.organizations = action.payload.sort((a, b) => stringToNumber(a.name) - stringToNumber(b.name) )
			})
			.addCase(getOrganizations.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.payload;
			})

	},
})

export const {
	setOrgLocale
  } = organizationsSlice.actions
export default organizationsSlice.reducer

