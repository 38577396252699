import React, { useEffect, useRef, useState } from 'react'
import 'mapbox-gl/dist/mapbox-gl.css'
import './Direction.scss'
import MapboxWorker from 'mapbox-gl/dist/mapbox-gl-csp-worker'
import mapboxgl from 'mapbox-gl/dist/mapbox-gl'
import s from './MapBox.module.scss'
import { useTranslation } from 'react-i18next'
import { stringToNumber } from '../../helper'

const getCoordinates = (point) => {
  if (point.organization) {
    return [point.organization.longitude, point.organization.latitude]
  } else if (point.place) {
    return [point.place.longitude, point.place.latitude]
  } else {
    return null
  }
}

export const MapBox = ({ points, center, isItem }) => {
  const mapRef = useRef(null)
  const [map, setMap] = useState(null)
  const [userMarker, setUserMarker] = useState(null)
  const [routeSet, setRouteSet] = useState(false)
  const [userLocation, setUserLocation] = useState(null)
  const [markers, setMarkers] = useState([])
  const [selectedMarkerCoords, setSelectedMarkerCoords] = useState(null)
  const [showRouteButton, setShowRouteButton] = useState(false)

  const { t } = useTranslation()

  useEffect(() => {
    const watchId = navigator.geolocation.watchPosition(
      (position) => {
        const { latitude, longitude } = position.coords
        setUserMarker(null)
        setUserLocation([longitude, latitude])
      },
      (error) => {
        console.error("Geolocation error:", error.message)
      }
    )
    if (mapRef.current) {
      mapboxgl.workerClass = MapboxWorker
      mapboxgl.accessToken = "pk.eyJ1Ijoic2h1c3RpayIsImEiOiJjbGt1d3VzcDQwNDluM2NwMDM4ZHJ6NDlrIn0.7n2_OTI_yAVrm7SoOFwLiw"
      setMap(
        new mapboxgl.Map({
          container: mapRef.current,
          style: 'mapbox://styles/mapbox/streets-v12',
          center: center && center[0] ? center : [27.61291529630767, 53.91357],
          zoom: center && center[0] ? 16 : 14
        })
      )
      handleRemoveGeoMarker()
    }
    return () => {
      navigator.geolocation.clearWatch(watchId)
      clearContainer()
    }
  }, [center])

  useEffect(() => {
    if (map && userLocation) {
      if (!userMarker) {
        const markerElement = document.createElement('div')
        markerElement.className = 'geolocation'
        markerElement.innerHTML = '<div></div>'

        const marker = new mapboxgl.Marker({ element: markerElement })
          .setLngLat(userLocation)
          .addTo(map)
        setUserMarker(marker)
      } else {
        userMarker.setLngLat(userLocation)
      }
    }
  }, [userMarker, map, userLocation])

  const getRoute = async (end, start) => {
    const query = await fetch(
      `https://api.mapbox.com/directions/v5/mapbox/walking/${start[0]},${start[1]};${end[0]},${end[1]}?alternatives=true&continue_straight=true&geometries=geojson&overview=full&steps=true&access_token=${mapboxgl.accessToken}`,
      { method: 'GET' }
    )
    const json = await query.json()
    const data = json.routes[0]
    const route = data.geometry.coordinates
    const geojson = {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates: route,
      },
    }
    if (map.getSource('route')) {
      map.getSource('route').setData(geojson)
    } else {
      map.addLayer({
        id: 'route',
        type: 'line',
        source: {
          type: 'geojson',
          data: geojson,
        },
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': '#E05B5B',
          'line-width': 5,
          'line-opacity': 0.9,
        },
      })
    }
    setRouteSet(true)
  }

  const clearRoute = () => {
    if (map.getSource('route')) {
      map.getSource('route').setData({ type: 'FeatureCollection', features: [] })
    }
    setRouteSet(false)
  }

  const handleRemoveGeoMarker = () => {
    const childElements = mapRef.current.getElementsByClassName('child')
    for (let i = childElements.length - 1; i >= 0; i--) {
      mapRef.current.removeChild(childElements[i])
    }
  }

  useEffect(() => {
    if (map && points?.length) {
      markers.forEach((marker) => marker.remove())
      setMarkers([])
      if (mapRef.current.getElementsByClassName('mapboxgl-ctrl-fullscreen').length === 0) {
        map.addControl(new mapboxgl.FullscreenControl(), 'top-right')
      }
      if (mapRef.current.getElementsByClassName('mapboxgl-ctrl-zoom-in').length === 0) {
        map.addControl(new mapboxgl.NavigationControl(), 'top-right')
      }
  
      const newMarkers = points.map((point) => {
        const coordinates = getCoordinates(point)
  
        // Создаем элемент для rконтейнера со ссылкой
        const pointLinkContainer = document.createElement('a')
        pointLinkContainer.className = 'card_item'
        pointLinkContainer.style.display = 'none'
        pointLinkContainer.href = point.organization ? `/organizations/${stringToNumber(point.name)}` : `/history/places/${stringToNumber(point.name)}`
  
        const cardImg = document.createElement('img')
        cardImg.className = 'card_img'
        cardImg.src = point.organization ? point.organization.logo : point.place.logo

        const cardText = document.createElement('div')
        cardText.className = 'card_text'
        cardText.innerText = point.name

        pointLinkContainer.appendChild(cardImg)
        pointLinkContainer.appendChild(cardText)
        //элемент маркера
        const markerElement = document.createElement('div')
        
        markerElement.className = 'map_point'
        markerElement.innerText = stringToNumber(point.name)
  
        //Cсылкa-контейнер в маркер
        markerElement.appendChild(pointLinkContainer)
  
        //Mаркер с кастомным элементом
        const marker = new mapboxgl.Marker({ element: markerElement })
          .setLngLat(coordinates)
          .addTo(map)

        marker.getElement().addEventListener('click', (e) => {
          if(pointLinkContainer.style.display == 'flex') {
            pointLinkContainer.style.display = 'none'
          }
          pointLinkContainer.style.display = 'flex'
          pointLinkContainer.style.position = 'absolute'
          pointLinkContainer.style.top = '-105px'

          setSelectedMarkerCoords(coordinates)
          setShowRouteButton(true)
          map.flyTo({
            center: coordinates,
            zoom: 14,
            speed: 1.2,
            curve: 1,
            easing: (t) => t,
          })
          
          const hidePointName = (e) => {            
            if (!markerElement.contains(e.target)) {
              pointLinkContainer.style.display = 'none'
              setShowRouteButton(false)
              document.removeEventListener('click', hidePointName)
            }
          }
          clearRoute()
          document.addEventListener('click', hidePointName)
        })
  
        return marker
      })
  
      setMarkers(newMarkers)
    }
  }, [map, points])
  

  const clearContainer = () => {
    if (mapRef.current) {
      mapRef.current.innerHTML = ''
    }
  };

  const handleRouteButtonClick = () => {
    if (selectedMarkerCoords && userLocation) {
      getRoute(selectedMarkerCoords, userLocation)
      setShowRouteButton(false)
    }
  };

  return (
    <>
      {!userLocation && <div className='geolocation_error'>{t('geoLocationError')}</div>}
      <div ref={mapRef} className={isItem ? s.mapRef_item : s.mapRef}>
        {routeSet && (
          <button className={s.clear_route} onClick={() => clearRoute()}>
            Очистить маршрут
          </button>
        )}
        {showRouteButton && (
          <button className={s.clear_route} onClick={handleRouteButtonClick}>
            Проложить маршрут
          </button>
        )}
      </div>
    </>
  );
};
