import { useSelector } from 'react-redux';
import s from './OrgItem.module.scss'
import { useParams } from 'react-router-dom'
import { MapBox } from '../../components/MapBox/MapBox';
import { LinkBox } from '../../components/LinkBox/LinkBox';
import { AudioPlayerComponent } from '../../components/AudioPlayer/AudioPlayer';
import { useTranslation } from 'react-i18next';
import { NavigationBar } from '../../components/NavigationBar/NavigationBar';
import { stringToNumber } from '../../helper';

export const OrgItem = () => {
    const { organizations } = useSelector(state => state.organizations);
    const { id } = useParams()
    const { t } = useTranslation()

    const getPlaceData = (id) => {
        const organization = organizations?.find(org => stringToNumber(org.name) === id);
        return organization ? organization : null
    }
    const organizationData = getPlaceData(Number(id))
    const pointInMap = organizations?.filter(org=> stringToNumber(org.name) === Number(id))
    window.scrollTo({ top: 0 })
    return (
        <div>
            <div className={s.high_title}>
                {t('organization')}
            </div>
            <div className={s.wrapper}>
            <NavigationBar
                    mainPage={t('mainLink')} 
                    secondLevel={t('organization')} 
                    secondLink={'/organizations'}
                    thirdLevel={organizationData?.name}/>	
                <div className={s.person_title}>
                    {organizationData?.name}
                </div>
                <div className={s.person_information}>
                    <div className={s.left_block}>
                        <img src={organizationData?.organization.images[1]} alt="" />
                        <AudioPlayerComponent src={organizationData?.audio} data={organizations} link={'/organizations'}/>
                    </div>
                    <div className={s.right_block}>
                        <div
                            dangerouslySetInnerHTML={{ __html: organizationData?.about }}
                        />
                        <div className={s.links}>
                            <LinkBox
                                title={t('branches')}
                                path={`/organizations/${id}/research-areas`} />
                            <LinkBox
                                title={t('achievements')}
                                path={`/organizations/${id}/achievements`} />
                            <LinkBox
                                title={t('contacts')}
                                path={`/organizations/${id}/contacts`} />
                        </div>
                    </div>

                </div>
                <MapBox 
                    points={pointInMap} 
                    center={[organizationData?.organization.longitude, organizationData?.organization.latitude]}
                    isItem={true}  />
            </div>
        </div>
    )
}