import s from './NewsItem.module.scss'

export const NewsItem = (props) => {
    return (
        <div className={s.news}>
            {props.image && <img src={img} alt="" />}
            <div className={s.news_text}>
                <div className={s.title}>
                {props.start_date}
                </div>
                <div className={s.text} 
                    dangerouslySetInnerHTML={{ __html: props.text }} />
            </div>
        </div>
    )

}