import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { stringToNumber } from "../helper";


export const getPlaces = createAsyncThunk(
  'places/fetchText',
  async (language, { rejectWithValue }) => {

    try {
      const response = await fetch(`https://nasbguide.krokam.by/api/rest/placelocales/?lang=${language}`)
      if (!response.ok) {

        throw new Error(response.statusText);
      }
      const data = await response.json()
      return data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)


const placesSlice = createSlice({
  name: 'places',
  initialState: {
    error: null,
    status: null,
    places: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPlaces.pending, (state) => {
        state.status = 'loading';
        state.error = null
      })
      .addCase(getPlaces.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.places = action.payload.sort((a, b) => stringToNumber(a.name) - stringToNumber(b.name))
      })
      .addCase(getPlaces.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
     
  },
})

export default placesSlice.reducer

