import s from './Persons.module.scss'
import { ReactComponent as Filter } from '../../assets/filter.svg'
import { ReactComponent as Search } from '../../assets/search.svg'
import { CardItem } from '../../components/CardItem/CardItem'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { NavigationBar } from '../../components/NavigationBar/NavigationBar'


export const Persons = () => {
    const { persons } = useSelector(state => state.persons)
    const { t } = useTranslation()
    const [searchTerm, setSearchTerm] = useState('')

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value)
    }
    const filteredPersons = persons?.filter(person =>
        person.name.toLowerCase().includes(searchTerm.toLowerCase())
    )

    window.scrollTo({ top: 0 })
    return (
        <div>
            <div className={s.high_title}>
                {t('person')}
            </div>
            <div className={s.wrapper}>
                <NavigationBar 
                    mainPage={t('mainLink')} 
                    secondLevel={t('history')} 
                    secondLink={'/history'} 
                    thirdLevel={t('person')}/>	
                <div className={s.setting_line}>
                    <div className={s.filter_search_container}>
                        <div className={s.search}>
                            <input
                                type="text"
                                value={searchTerm}
                                onChange={handleSearchChange}
                                placeholder={t('personPlaceholder')}
                            />
                            <Search />
                        </div>
                    </div>
                </div>
                <div className={s.persons_container}>
                    {filteredPersons?.map((person, index) => (
                        <CardItem
                            key={index}
                            link={`history/persons/${index}`}
                            image={person.person.logo}
                            text={person.name} />
                    ))}
                </div>
            </div>
        </div>
    )
}