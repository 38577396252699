import { Route, Routes } from "react-router-dom";
import { Main } from "./pages/Main/main";
import { Header } from "./components/Header/Header";
import { Footer } from "./components/Footer/Footer";
import { History } from "./pages/History/History";
import { Organizations } from "./pages/Organizations/Organizations";
import { Development } from "./pages/Development/Development";
import { Persons } from "./pages/Persons/Persons";
import { Person } from "./pages/Person/Person";
import { DatePage } from "./pages/DatePage/DatePage";
import { Places } from "./pages/Places/Places";
import { Place } from "./pages/Place/Place";
import { OrgItem } from "./pages/OrgItem/OrgItem";
import { DevItem } from "./pages/DevItem/DevItem";
import { ResearchAreas } from "./pages/OrgItem/Links/ResearchAreas";
import { Achievements } from "./pages/OrgItem/Links/Achievements";
import { Contacts } from "./pages/OrgItem/Links/Contacts";

export const App = () => {
  
  return (
    <div className="App">
      <Header />
      <Routes>
          <Route path='/' element={<Main />} />
          <Route path='/history' element={<History />} />
          <Route path='/organizations' element={<Organizations />} />
          <Route path='/organizations/:id' element={<OrgItem />} />
          <Route path='/organizations/:id/research-areas' element={<ResearchAreas />} />
          <Route path='/organizations/:id/achievements' element={<Achievements />} />
          <Route path='/organizations/:id/contacts' element={<Contacts />} />
          <Route path='/development' element={<Development />} />
          <Route path='/development/:id' element={<DevItem />} />
          <Route path='/history/date' element={<DatePage />} />
          <Route path='/history/persons' element={<Persons />} />
          <Route path='/history/persons/:id' element={<Person />} />
          <Route path='/history/places' element={<Places />} />
          <Route path='/history/places/:id' element={<Place />} />
      </Routes>      
      <Footer />
    </div>
  );
}
