import { useNavigate } from 'react-router-dom'
import s from './NavigationBar.module.scss'

export const NavigationBar = ({ mainPage, secondLevel, secondLink, thirdLevel, thirdLink, fourthLevel }) => {
    const navigate = useNavigate()
    const navigateFun = (link) => {
        navigate(link)
    }
    return (
        <div className={s.navigation}>
            <span className={s.main_link} onClick={() => navigateFun('/')}>{mainPage}</span>
            <span className={s.slash}>/</span>
            <span
                className={thirdLevel ? s.main_link : s.last_link}
                onClick={thirdLevel ? () => navigateFun(secondLink) : undefined}>
                {secondLevel}
            </span>
            {thirdLevel && (
                <>
                    <span className={s.slash}>/</span>
                    <span 
                        className={fourthLevel ? s.main_link : s.last_link}
                        onClick={fourthLevel ? () => navigateFun(thirdLink) : undefined}>
                            {thirdLevel}
                        </span>
                </>
            )}
            {fourthLevel && (
                <>
                    <span className={s.slash}>/</span>
                    <span className={s.last_link}>{fourthLevel}</span>
                </>
            )}
        </div>
    )
}