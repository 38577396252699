import { Link } from 'react-router-dom'
import s from './main.module.scss'
import history from '../../assets/histiory.png'
import nan from '../../assets/nan.png'
import dev from '../../assets/development.png'
import { ReactComponent as Arrow } from '../../assets/arrow.svg'
import { useEffect } from 'react'
import './animation.css'
import { useTranslation } from 'react-i18next'

const scrollReveal = () => {
    const revealElements = document.querySelectorAll("[data-reveal]")
    for (let i = 0; i < revealElements.length; i++) {
        const isElementsOnScreen = revealElements[i].getBoundingClientRect().top < window.innerHeight
        if (isElementsOnScreen) {
            revealElements[i].classList.add("revealed")
        } else {
            revealElements[i].classList.remove("revealed")
        }
    }
}

export const Main = () => {
    const { t } = useTranslation()
    useEffect(() => {
        window.addEventListener("scroll", scrollReveal)
        window.addEventListener("load", scrollReveal)

        return () => {
            window.removeEventListener("scroll", scrollReveal)
            window.removeEventListener("load", scrollReveal)
        }
    }, [])

    return (
        <>
            <div className={s.main_cont}>
                <div className={s.title_container}>
                    <div className={s.first_title}>
                        {t('mainTitle')}
                    </div>
                    <div className={s.second_title}>
                    {t('mainTitle2')}
                    </div>
                </div>
                {/* <div className={s.buttons_container}>
                    <Link className={s.link_button}>
                        IOS
                    </Link>
                    <Link className={s.link_button}>
                        Android
                    </Link>
                </div> */}
            </div>
            <div>
                <div className={s.data_container}>
                    <div className={s.data_body}>
                        <img data-reveal="left" src={history} alt="" />
                        <Link to={'/history'} data-reveal="left" className={s.data_text}>
                            <div className={s.title}>
                                {t('history')}
                                <div className={s.background_title}>
                                {t('history')}
                                </div>
                            </div>
                            <div className={s.text}>
                            {t('historyText')}
                            </div>
                            <div className={s.nav_circle}>
                                <Arrow />
                            </div>
                        </Link>
                    </div>
                    <div className={s.data_body}>
                        <Link to={'/organizations'} data-reveal="left" className={s.data_text}>
                            <div className={s.title}>
                                {t('organization')}
                                <div className={s.background_title}>
                                {t('organization')}
                                </div>
                            </div>
                            <div className={s.text}>{t('organizationText')}
                            </div>
                            <div className={s.nav_circle}>
                                <Arrow />
                            </div>
                        </Link>
                        <img data-reveal="left" src={nan} alt="" />
                    </div>
                    <div className={s.data_body}>
                        <img data-reveal="left" src={dev} alt="" />
                        <Link to={'/development'} data-reveal="left" className={s.data_text}>
                            <div className={s.title}>
                                {t('development')}
                                <div className={s.background_title}>
                                {t('development')}
                                </div>
                            </div>
                            <div className={s.text}>
                            {t('developmentText')}
                            </div>
                            <div className={s.nav_circle}>
                                <Arrow />
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </>

    )
}