import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslations from './locales/en/en.json';
import beTranslations from './locales/be/be.json';
import ruTranslations from './locales/ru/ru.json'

const savedLanguage = localStorage.getItem('language');
i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enTranslations,
      },
      be: {
        translation: beTranslations,
      },
      ru: {
        translation: ruTranslations,
      }
    },
    lng: savedLanguage || 'be', 
    fallbackLng: 'be', 
    interpolation: {
      escapeValue: false, 
    },
  });

export default i18n;