import s from './History.module.scss'
import { ReactComponent as Arrow } from '../../assets/arrow.svg'
import calendar from '../../assets/calendar.png'
import persons from '../../assets/persons.png'
import places from '../../assets/places.png'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { NavigationBar } from '../../components/NavigationBar/NavigationBar'


export const History = () => {
    const { t } = useTranslation()
    window.scrollTo({ top: 0 })
    return (
        <div>
            <div className={s.high_title}>
                {t('history')}
            </div>
            <div>
                <div className={s.data_container}>
                <NavigationBar mainPage={t('mainLink')} secondLevel={t('history')}/>
                    <div className={s.data_body}>
                        <img src={calendar} alt="" />
                        <Link to={'/history/date'} className={s.data_text}>
                            <div className={s.title}>
                                {t('date')}
                                <div className={s.background_title}>
                                {t('date')}
                                </div>
                            </div>
                            <div className={s.text}>
                            {t('dateText')}
                            </div>
                            <div className={s.nav_circle}>
                                <Arrow />
                            </div>
                        </Link>
                    </div>
                    <div className={s.data_body}>
                        <Link to={'/history/persons'} className={s.data_text}>
                            <div className={s.title}>
                            {t('person')}
                                <div className={s.background_title}>
                                {t('person')}
                                </div>
                            </div>
                            <div className={s.text}>{t('personText')}
                            </div>
                            <div className={s.nav_circle}>
                                <Arrow />
                            </div>
                        </Link>
                        <img src={persons} alt="" />
                    </div>
                    <div className={s.data_body}>
                        <img src={places} alt="" />
                        <Link to={'/history/places'} className={s.data_text}>
                            <div className={s.title}>
                            {t('places')}
                                <div className={s.background_title}>
                                {t('places')}
                                </div>
                            </div>
                            <div className={s.text}>
                            {t('placesText')}
                            </div>
                            <div className={s.nav_circle}>
                                <Arrow />
                            </div>
                        </Link>
                    </div>
                </div>
            </div>    
        </div>
    )
}