import { useEffect, useRef, useState } from 'react';
import i18n from '../../../i18n';
import { ReactComponent as Language } from '../../../assets/language.svg'
import s from './SelectLanguage.module.scss'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getPersons } from '../../../store/personsSlice';
import { getEvents, setLocale } from '../../../store/eventsSlice';
import { getPlaces } from '../../../store/placesSlice';
import { getOrganizations, setOrgLocale } from '../../../store/organizationsSlice';
import { getDevs, setDevLocale } from '../../../store/devsSlice';

export const SelectLanguage = () => {
    const [language, setLanguage] = useState(localStorage.getItem('language') || 'be')
    const optionBodyRef = useRef(null)
    const [isOpen, setIsOpen] = useState(false)
    const dispatch = useDispatch()
    const { t } = useTranslation()
   
    const changeLanguage = (e) => {
        i18n.changeLanguage(e);
        setLanguage(e)
    }

    const closeOptionBody = (event) => {
        if (optionBodyRef.current && !optionBodyRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        localStorage.setItem('language', language)
        
        document.addEventListener('click', closeOptionBody)
        document.title = t('documentTitle')
        const languages = () => {
            switch (language) {
              case 'en':
                return 1
              case 'be':
                return 2
              case 'ru':
                return 3
              default:
                return 2
            }
          }
        dispatch(getPersons(languages()))
        dispatch(setLocale(language))
        dispatch(getEvents(languages()))
        dispatch(getPlaces(languages()))
        dispatch(getOrganizations(languages()))
        dispatch(getDevs(languages()))        
        dispatch(setOrgLocale(languages()))
        dispatch(setDevLocale(languages()))
        return () => {
            document.removeEventListener('click', closeOptionBody)
        };
    }, [language, t]);

    return (
        <div
            ref={optionBodyRef}
            onClick={() => setIsOpen(!isOpen)}
            className={s.languages_container}>
            <div className={s.selected_language}>
                <Language />
            </div>

            {isOpen ?
                <div className={s.languages}>
                    <div className={language === 'be' ? s.lan_item : null} onClick={() => changeLanguage('be')}>BE</div>
                    {/* <div className={language === 'en' ? s.lan_item : null} onClick={() => changeLanguage('en')}>EN</div> */}
                    <div className={language === 'ru' ? s.lan_item : null} onClick={() => changeLanguage('ru')}>RU</div>
                    {/* <div className={language === '中文' ? s.lan_item : null} onClick={() => changeLanguage('中文')}>中文</div> */}
                </div> :
                null}
        </div>
    )
}