import { useState, useRef, useEffect } from 'react';
import s from './AudioPlayer.module.scss'
import { NavigationPannelMobile } from './NavigationPannelMobile';

export const AudioPlayerComponent = ({ src, data, link }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [isMuted, setIsMuted] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [playbackRate, setPlaybackRate] = useState(false);
    const audioRef = useRef(null);


    const handlePlayPause = () => {
        if (src) {
            if (isPlaying) {
                audioRef.current.pause()
            } else {
                audioRef.current.play()
            }
            setIsPlaying(!isPlaying)
        }

    };

    const handleMuteUnmute = () => {
        setIsMuted(!isMuted);
        audioRef.current.muted = !isMuted;
    };

    const handleTimeUpdate = () => {
        setCurrentTime(audioRef.current.currentTime);
    };

    const handleLoadedMetadata = () => {
        setDuration(audioRef.current.duration);
    };

    const changePlaybackRate = (rate) => {
        audioRef.current.playbackRate = rate;
        setPlaybackRate(false);
    };

    const durationSec = Math.trunc(duration % 60)
    const durationMin = Math.trunc(duration / 60)
    const progressSec = Math.trunc(currentTime % 60)
    const progressMin = Math.trunc(currentTime / 60)

    useEffect(() => {
        if (audioRef) {
            const audioElement = audioRef.current;
            audioElement.addEventListener('timeupdate', handleTimeUpdate);
            audioElement.addEventListener('loadedmetadata', handleLoadedMetadata);

            return () => {
                audioElement.removeEventListener('timeupdate', handleTimeUpdate);
                audioElement.removeEventListener('loadedmetadata', handleLoadedMetadata);
            };
        }

    }, [audioRef]);

    useEffect(() => {
        if (src) {
            setIsPlaying(false)
            setIsMuted(false)
            audioRef.current.muted = false;
        } else audioRef.current.pause()
    }, [src])

    return (
        <div className={s.player_container}>
            <NavigationPannelMobile data={data} link={link} handlePlayPause={handlePlayPause} isPlaying={isPlaying} progressMin={progressMin} progressSec={progressSec}
                currentTime={currentTime} duration={duration} durationMin={durationMin} durationSec={durationSec} handleMuteUnmute={handleMuteUnmute} isMuted={isMuted}
                setPlaybackRate={setPlaybackRate} playbackRate={playbackRate} audioRef={audioRef} changePlaybackRate={changePlaybackRate} />
            <div>
                <audio ref={audioRef} src={src} preload="auto"></audio>
            </div>
        </div>
    );
};